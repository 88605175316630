import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { Container, Col, Row } from 'components/Layout/Grid';

import { smallTablet } from 'helper/constants/mediaRules';
import {
  FONT_SIZE_404_TITLE_MOBILE,
  FONT_SIZE_DOCS_H2,
  JAVA_GREEN,
} from 'helper/constants/styles';
import strings from 'helper/localization/localization';

import Ufo from '../../src/images/404_disc.svg';

import SEO from '../components/Seo';

const Col404 = styled(Col)`
  text-align: center;
  margin-top: 6rem;
  position: relative;

  ${smallTablet(css`
    margin-top: 10rem;
  `)}

  > img {
    position: absolute;
    right: 0;
    top: -33%;
    width: 200px;

    ${smallTablet(css`
      top: -30%;
      width: auto;
    `)}
  }

  > h1 {
    font-size: ${FONT_SIZE_404_TITLE_MOBILE};
    color: ${JAVA_GREEN};
    margin: 0;
    display: inline-flex;

    ${smallTablet(css`
      font-size: 10rem;
    `)}
  }
`;

const Body404 = styled.h3`
  margin-top: 2rem;
  line-height: 2.4rem;
  padding: 0 22px;
  font-size: ${FONT_SIZE_DOCS_H2};
`;

const Link404 = styled(Link)`
  text-decoration: none;
  font-size: ${FONT_SIZE_DOCS_H2};

  &:hover {
    text-decoration: underline;
  }
`;

const Row404 = styled(Row)`
  justify-content: center;
`;

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Container>
      <Row404>
        <Col404 id="qa-404-page">
          <img src={Ufo} alt="" />
          <h1>
            {'404'}
          </h1>
          <Body404>
            {strings.title404}
          </Body404>
          <Link404 id="qa-404-link" to="/">{strings.link404}</Link404>
        </Col404>
      </Row404>
    </Container>
  </>
);

export default NotFoundPage;
